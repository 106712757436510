<template>
  <div>
    <div class="_main_card_header">
      <div class="__header_inner admin_header_padding">
        <div
          class="side_nav_wrapper"
          style="overflow-x: auto; min-height: 3rem; margin-left: auto">
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/fraud-detection'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/fraud-detection').catch((err) => {})
              ">
              Messages ({{ messages_count }})
            </button>
          </div>
          <!-- <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/fraud-detection/whitelist'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/fraud-detection/whitelist')
                  .catch((err) => {})
              ">
              White Listed Users
            </button>
          </div> -->
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/fraud-detection/keywords'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/fraud-detection/keywords')
                  .catch((err) => {})
              ">
              Keywords ({{ keywords_count }})
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main_card_body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        messages_count: 0,
        keywords_count: 0,
      };
    },
    mounted() {
      this.$store
        .dispatch("admin/fraudSystem/getFraudulentMessages", {
          page: 1,
          search: "",
        })
        .then((data) => {
          this.messages_count = data.total;
        });
      this.$store
        .dispatch("admin/fraudSystem/getKeywords", {
          page: 1,
          search: "",
        })
        .then((data) => {
          this.keywords_count = data.total;
        });
    },
  };
</script>

<style scoped></style>
